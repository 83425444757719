<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col><h2>Hamochi Care</h2></v-col>
        <v-col class="text-right">
          <v-btn
            rounded
            :to="{
              name: 'PageEducatorInstitutionSponsoredStudentAdd',
              params: {parentId: this.$route.params.id },
            }"
            color="primary"
          >
            + Apply
          </v-btn>
          <!-- <v-btn
          class="ml-2"
            rounded
            :to="{
              name: 'PageEducatorInstitutionClassroomStudentAdd',
              params: { parentId: this.$route.params.parentId,id: this.$route.params.id },
            }"
            color="primary"
          >
            + New Student
          </v-btn> -->
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <v-card-subtitle>Hamochi is committed to making a positive impact in society. We provide educational aid for underprivileged students, allowing teachers to easily apply for full-feature access on their behalf. This is made possible thanks to the generosity of local sponsors, who believe in empowering the next generation through education.</v-card-subtitle>
      <v-card-text>

     <!-- BOC:[tabs] -->
     <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab v-for="item in tabs" :key="item.key" :href="`#${item.key}`">
        {{ item.label }}
      </v-tab>
    </v-tabs>
     <!-- BOC:[tab] -->
     <v-tabs-items v-model="tab">
        <!-- BOC:[model] -->
        <v-tab-item key="all" value="all" class="px-1 py-2">
        <ListAll :url="`${$api.servers.sso}/api/v1/${$_getLocale()}/educator/institution/${$route.params.id}/sponsoredStudent`" :headers="headers" :callbackClick="clickRead"></ListAll>
      </v-tab-item>
      <!-- EOC -->
       <!-- BOC:[model] -->
       <v-tab-item key="pending" value="pending" class="px-1 py-2">
        <ListAll :url="`${$api.servers.sso}/api/v1/${$_getLocale()}/educator/institution/${$route.params.id}/sponsoredStudent?status=UNDER_REVIEW`" :headers="headers" :callbackClick="clickRead"></ListAll>
      </v-tab-item>
      <!-- EOC -->
        <!-- BOC:[model] -->
      <v-tab-item key="query" value="query" class="px-1 py-2">
        <ListAll :url="`${$api.servers.sso}/api/v1/${$_getLocale()}/educator/institution/${$route.params.id}/sponsoredStudent?status=IN_QUERY`" :headers="headers" :callbackClick="clickRead"></ListAll>
      </v-tab-item>
      <!-- EOC -->
      <!-- BOC:[model] -->
      <v-tab-item key="approved" value="approved" class="px-1 py-2">
        <ListAll :url="`${$api.servers.sso}/api/v1/${$_getLocale()}/educator/institution/${$route.params.id}/sponsoredStudent?status=APPROVED`" :headers="headers" :callbackClick="clickRead"></ListAll>
      </v-tab-item>
      <!-- EOC -->
       <!-- BOC:[model] -->
      <v-tab-item key="expired" value="expired" class="px-1 py-2">
        <ListAll :url="`${$api.servers.sso}/api/v1/${$_getLocale()}/educator/institution/${$route.params.id}/sponsoredStudent?status=EXPIRED`" :headers="headers" :callbackClick="clickRead"></ListAll>
      </v-tab-item>
      <!-- EOC -->
        <!-- BOC:[model] -->
        <v-tab-item key="rejected" value="rejected" class="px-1 py-2">
        <ListAll :url="`${$api.servers.sso}/api/v1/${$_getLocale()}/educator/institution/${$route.params.id}/sponsoredStudent?status=REJECTED`" :headers="headers" :callbackClick="clickRead"></ListAll>
      </v-tab-item>
      <!-- EOC -->
       <!-- BOC:[model] -->
       <v-tab-item key="terminated" value="terminated" class="px-1 py-2">
        <ListAll :url="`${$api.servers.sso}/api/v1/${$_getLocale()}/educator/institution/${$route.params.id}/sponsoredStudent?status=CANCELED`" :headers="headers" :callbackClick="clickRead"></ListAll>
      </v-tab-item>
      <!-- EOC -->
    </v-tabs-items>
    <!-- EOC -->
             
  </v-card-text>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import ListAll from "../../components/Datatable/ListAll.vue";
// import ShareLink from "../../components/Dashboard/ShareLink.vue";
export default {
  components: {
    ListAll,
    // ShareLink
    //
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  // computed: mapState({
  //     auth: (state) => state.auth.data,
  //   }),
  props: [
    //
  ],
  data: () => ({
    tab: null,
    tabs: [
      {
        key: "all",
        label: "All",
      },
      {
        key: "approved",
        label: "Approved",
      },
      {
        key: "pending",
        label: "Under Review",
      },
      {
        key: "query",
        label: "In Query",
      },
      {
        key: "rejected",
        label: "Rejected",
      },
      {
        key: "expired",
        label: "Expired",
      },
      {
        key: "terminated",
        label: "Terminated",
      },
    ],
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    url: null,
    sponsorship:{},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "index",
      },
      { text: "Applied", value: "dateCreated" },
      { text: "Avatar", value: "studentAvatar" },
      { text: "Student", value: "Student.name" },
      { text: "Classroom", value: "Student.Classroom.name" },
      { text: "Status", value: "sponsoredStudentstatus" },
      { text: "Applied By", value: "sponsoredStudentAppliedBy" },
      { text: "", value: "button", align: "end" },
    ],
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
      {
        text: "Hamochi Care",
        to: { name: "PageEducatorInstitutionSponsoredStudent" },
        exact: true,
      },
    ];
    //EOC
    this.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.parentId+"/classroom/" +
      this.$route.params.id +
      "/student";

      this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
       "/educator/institution/"+this.$route.params.parentId+"/sponsorship/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.sponsorship = resp
    
    };
  },
  mounted() {
  //  this.$api.fetch(this.api);
  },
  methods: {
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.Institution.name,
    //     to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.parentId} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: 'Classrooms',
    //     to: { name: "PageEducatorInstitutionClassroom",params:{id:this.$route.params.parentId} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.name,
    //     to: { name: "PageEducatorInstitutionClassroomDashboard" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId}},
    //     exact: true,
    //   });

    //   this.breadcrumbs.push({
    //   text: "Students",
    //   to: { name: "PageEducatorInstitutionClassroomStudent" },
    //   exact: true,
    // });
    // },
    clickRead(item) {
      this.$router.push({
        name: "PageEducatorInstitutionSponsoredStudentRead",
        params: {grandparentId: this.$route.params.parentId, parentId: this.$route.params.id, id: item.id },
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>